@font-face {
  font-family: 'overpass';
  src: url('overpass-thin.woff2') format('woff2');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-thin-italic.woff2') format('woff2');
  font-weight: 200;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-extralight.woff2') format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-extralight-italic.woff2') format('woff2');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-light.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-light-italic.woff2') format('woff2');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-regular.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-italic.woff2') format('woff2');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-semibold.woff2') format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-semibold-italic.woff2') format('woff2');
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-bold.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-bold-italic.woff2') format('woff2');
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-extrabold.woff2') format('woff2');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-extrabold-italic.woff2') format('woff2');
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-heavy.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'overpass';
  src: url('overpass-heavy-italic.woff2') format('woff2');
  font-weight: 900;
  font-style: italic;
}
